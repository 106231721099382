const devConfig = {
  URLs: {
    generateJWT:
      "URL",
    startConversation:"URL", 
    
  processConversation:"https://zeb.theoceanac.com/process_conversation",
  dashboard:"https://zeb.theoceanac.com/dashboard",
  duration:"https://zeb.theoceanac.com/duration",
  satisfaction:"https://zeb.theoceanac.com/satisfaction"
  
  }
};

export default devConfig;
